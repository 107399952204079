// FONT FAMILY
export const primaryFont = "'Poppins', sans-serif";
export const secondaryFont = "'Inter', sans-serif";

// TYPOGRAPHY
export const Heading = {
  header1: "1.8rem",
  header2: "1.6rem",
  header3: "1.4rem",
  header4: "1.2rem",
  header5: "1.1rem",
  paragraph: "1rem",
  helperText: "0.8rem",
};
export const fontSize = {
  9: "0.5rem",
  10: "0.625rem",
  12: "0.75rem",
  14: "0.875rem",
  15: "0.938rem",
  16: "1rem",
  18: "1.125rem",
  20: "1.25rem",
  22: "1.375rem",
  24: "1.5rem",
  26: "1.625rem",
  28: "1.75rem",
  30: "1.875rem",
  32: "2rem",
  34: "2.125rem",
  36: "2.25rem",
  38: "2.375rem",
  40: "2.5rem",
  42: "2.625rem",
  48: "3rem",
  50: "3.125rem",
  52: "3.25rem",
  58: "3.625rem",
  62: "3.875rem",
  72: "4.5rem",
  82: "5.125rem",
  92: "5.75rem",
  100: "6.25rem",
};

// FORM FIELDS
export const unitSize = {
  fieldHeight: "42px",
  borderRadius: "6px",
  lineHeight: "28px",
  borderRadiusFull: "50%",
};

import React, { createContext, useContext, useState } from "react";

interface FilterTagsContextType {
  showFilterTags: boolean;
  setShowFilterTags: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterTagsContext = createContext<FilterTagsContextType | undefined>(
  undefined
);

export const useFilterTags = (): FilterTagsContextType => {
  const context = useContext(FilterTagsContext);
  if (!context) {
    throw new Error("useFilterTags must be used within a FilterTagsProvider");
  }
  return context;
};

export const FilterTagsProvider = ({ children }: { children: any }) => {
  const [showFilterTags, setShowFilterTags] = useState<boolean>(false);

  return (
    <FilterTagsContext.Provider value={{ showFilterTags, setShowFilterTags }}>
      {children}
    </FilterTagsContext.Provider>
  );
};

import { lazy } from "react";
const ProfileEdit = lazy(
  () => import("../pages/User/talentQuestionaire/ProfileEdit")
);
const ViewTalentProfile = lazy(
  () => import("../pages/User/profile/ViewTalentProfile")
);
const ViewRecruiterProfile = lazy(
  () => import("../pages/User/profile/ViewRecruiterProfile")
);
const TalentQuestionaire = lazy(
  () => import("../pages/User/talentQuestionaire/TalentQuestionaire")
);
const RecruiterQuestionaire = lazy(
  () => import("../pages/User/recruiterQuestionaire/RecruiterQuestionaire")
);
const TalentQuestionaireNew = lazy(
  () => import("../pages/User/talentQuestionaire/TalentQuestionaireNew")
);
const RecruiterQuestionaireNew = lazy(
  () => import("../pages/User/recruiterQuestionaire/RecruiterQuestionaireNew")
);
const TalentPool = lazy(() => import("../pages/User/talentPool/TalentPool"));
const TalentQuestionaireCreate = lazy(
  () => import("../pages/User/talentQuestionaire/TalentQuestionaireCreate")
);
const RecruiterQuestionaireCreate = lazy(
  () =>
    import("../pages/User/recruiterQuestionaire/RecruiterQuestionaireCreate")
);
const TalentPoolCreate = lazy(
  () => import("../pages/User/talentPool/TalentPoolCreate")
);
//Edit
const TalentQuestionaireViewEdit = lazy(
  () => import("../pages/User/talentQuestionaire/TalentQuestionaireViewEdit")
);
// Edit End
const RecruiterQuestionaireViewEdit = lazy(
  () =>
    import("../pages/User/recruiterQuestionaire/RecruiterQuestionaireViewEdit")
);
const TalentPoolViewEdit = lazy(
  () => import("../pages/User/talentPool/TalentPoolViewEdit")
);
const Profile = lazy(() => import("../pages/User/profile/Profile"));
const TalentProfile = lazy(() => import("../pages/User/profile/TalentProfile"));
const Dashboard = lazy(() => import("../pages/User/dashboard/Dashboard"));
const Chat = lazy(() => import("../pages/User/chat/Chat"));
const MyChat = lazy(() => import("../pages/User/chat/MyChat"));
const StreamChat = lazy(() => import("../pages/User/chat/StreamChat"));
const Community = lazy(() => import("../pages/User/chat/StreamCommunity"));
const MyCommunity = lazy(() => import("../pages/User/community/Feed"));
const NewProfile = lazy(() => import("../pages/User/profile/NewProfile"));
const SubscriptionPlans = lazy(
  () => import("../pages/User/stripe/SubscriptionPlans")
);
const RecruiterProfile = lazy(
  () => import("../pages/User/profile/RecruiterProfile")
);

const MyPosts = lazy(() => import("../pages/User/community/MyPosts"));
export const userCollectionRoutes = [
  {
    path: "/subscription-plans",
    component: SubscriptionPlans,
    name: "SubscriptionPlans",
  },
  {
    path: "/recruiter/profile",
    component: RecruiterProfile,
    name: "RecruiterProfile",
  },
  {
    path: "/talentQuestionaire",
    component: TalentQuestionaire,
    name: "TalentQuestionaire",
  },
  {
    path: "/profiles/:id",
    component: ViewTalentProfile,
    name: "ViewTalentProfile",
  },
  {
    path: "/recruiters/:id",
    component: ViewRecruiterProfile,
    name: "ViewRecruiterProfile",
  },

  {
    path: "/recruiterQuestionaire",
    component: RecruiterQuestionaire,
    name: "RecruiterQuestionaire",
  },
  {
    path: "/talent/Questionaire",
    component: TalentQuestionaireNew,
    name: "TalentQuestionaireNew",
  },
  {
    path: "/recruiter/Questionaire",
    component: RecruiterQuestionaireNew,
    name: "RecruiterQuestionaireNew",
  },
  {
    path: "/recruiter/dashboard",
    component: TalentQuestionaire,
    name: "RecruiterQuestionaireNew",
  },
  {
    path: "/recruiter/talentpool",
    component: TalentQuestionaire,
    name: "RecruiterQuestionaireNew",
  },
  {
    path: "/talentPool",
    component: TalentPool,
    name: "TalentPool",
  },
];

const viewEditRoutes = [
  {
    path: "/talentQuestionnaire/:id",
    component: ProfileEdit,
    name: "ProfileEdit",
  },

  {
    path: "/recruiterQuestionaire/:id",
    component: RecruiterQuestionaireViewEdit,
    name: "RecruiterQuestionaireViewEdit",
  },
  {
    path: "/talentPool/:id",
    component: TalentPoolViewEdit,
    name: "TalentPoolViewEdit",
  },
];
const createRoutes = [
  {
    path: "/talentQuestionaire/create",
    component: TalentQuestionaireCreate,
    name: "TalentQuestionaireCreate",
  },
  {
    path: "/recruiterQuestionaire/create",
    component: RecruiterQuestionaireCreate,
    name: "RecruiterQuestionaireCreate",
  },
  {
    path: "/talentPool/create",
    component: TalentPoolCreate,
    name: "TalentPoolCreate",
  },
];

const defaultRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/profile",
    component: Profile,
    name: "Profile",
  },
  {
    path: "/talent/profile",
    component: TalentProfile,
    name: "TalentProfile",
  },
  {
    path: "/recruiter/talentProfile/:id",
    component: NewProfile,
    name: "Profile",
  },
  {
    path: "/chat",
    component: Chat,
    name: "Chat",
  },
  {
    path: "/recruiter/community",
    component: MyCommunity,
    name: "Community",
  },
  {
    path: "/talent/community",
    component: MyCommunity,
    name: "Community",
  },
  {
    path: "/recruiter/community/mypost",
    component: MyPosts,
    name: "MyPosts",
  },
  {
    path: "/talent/community/mypost",
    component: MyPosts,
    name: "MyPosts",
  },
  {
    path: "/recruiter/chats",
    component: MyChat,
    name: "StreamChat",
  },
  {
    path: "/recruiter/chats/:id",
    component: MyChat,
    name: "StreamChat",
  },
  // {
  //   path: "/recruiter/chat/create/:id",
  //   component: StreamChat,
  //   name: "StreamChat",
  // },
  {
    path: "/recruiter/chat/create/:id",
    component: MyChat,
    name: "MyChat",
  },
  {
    path: "/talent/chat",
    component: MyChat,
    name: "StreamChat",
  },
];

const UserRoutes = [
  ...defaultRoutes,
  ...userCollectionRoutes,
  ...viewEditRoutes,
  ...createRoutes,
];
export default UserRoutes;

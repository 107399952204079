import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TalentPoolInterface, TalentPoolInterfaceCreated } from "../../../interface/TalentPoolInterface";

type initialState = {
        getAllStatePool: {
                talentPool: TalentPoolInterfaceCreated[],
                countPool: number
        },
        getOneState: TalentPoolInterface
}

const initState: initialState = {
        getAllStatePool: {
                talentPool: [],
                countPool: 0
        },
        getOneState: {
                talent: "", recruiter: "",
        }

};

const talentPool = createSlice({
        name: "talentPool",
        initialState: initState,
        reducers: {
                setAllPool: (state, action: PayloadAction<{ talentPool: TalentPoolInterfaceCreated[], countPool: number }>) => {
                        state.getAllStatePool = action.payload;
                },
                deleteOne: (state, action: PayloadAction<string>) => {
                        state.getAllStatePool.talentPool = state.getAllStatePool.talentPool.filter((item) => item._id !== action.payload)
                        state.getAllStatePool.countPool = state.getAllStatePool.countPool - 1
                },
                setOne: (state, action: PayloadAction<any>) => {
                        state.getAllStatePool.talentPool = [...state.getAllStatePool.talentPool, action.payload.data]
                        state.getAllStatePool.countPool = state.getAllStatePool.countPool + 1
                },
                getOne: (state, action: PayloadAction<any>) => {
                        state.getOneState = action.payload
                },
        },
});
export default talentPool.reducer;
export const { setAllPool, deleteOne, setOne, getOne } = talentPool.actions;

/**
 * @IMAGE  Image Path
 */

// SVG Icons
import BlackExport from "../assets/svg/black_export.svg";
import BlackRefresh from "../assets/svg/black_refresh.svg";
import BlackSearch from "../assets/svg/black_search.svg";
import WhiteFilter from "../assets/svg/white_filter.svg";
import DashboardIcon from "../assets/svg/dashboard_icon.svg";
import Edit from "../assets/icons/edit.svg";
import Trash from "../assets/icons/bag.svg";
import dashboardicon from "../assets/icons/dashboardicon.svg";
import defaultProfilePic from "../assets/icons/defaultImg.png";
import  Next  from "../assets/icons/nextIcon.png";
import  Prev  from "../assets/icons/prevIcon.png";
import emptyIcon from "../assets/icons/empty.png"
 

// PNG Icons

// Large Image
import placeholderImage from "../assets/placeholder.jpg";
import bylddLogo from "../assets/byldd-logo.png";
import Logo from "../assets/icons/spokeClub-logo.png";
import star from "../assets/icons/start.png";


// EXPORT IMAGES
export {
  placeholderImage,
  bylddLogo,
  BlackExport,
  BlackRefresh,
  BlackSearch,
  WhiteFilter,
  DashboardIcon,
  Edit,
  Trash,
  Logo,
  dashboardicon,
  defaultProfilePic,
  Next,
  Prev,
  star,
  emptyIcon
};

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// LAYOUT CONTAINER
export const mainStyle = css`
  .MuiContainer-root {
    @media (min-width: 1440px) {
      max-width: 1350px;
    }
  }
`;

// LAYOUT WITH GRID
export const grid2 = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 820px) {
    grid-template-columns: none;
  }
`;
export const grid = css`
  display: grid;
  gap: 24px;
`;
export const grid3 = css`
  display: grid;
  gap: 24px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const grid4 = css`
  display: grid;
  gap: 24px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const grid5 = css`
  display: grid;
  gap: 24px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
export const grid6 = css`
  display: grid;
  gap: 24px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;
export const grid7 = css`
  display: grid;
  gap: 24px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;

// LAYOUT WITH FLEX

export const flex = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  .flex {
    &_item {
      flex: 1 auto;
    }
  }
`;
export const flex2 = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  .flex {
    &_item {
      flex: 1 45%;
    }
  }
`;
export const flex3 = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  .flex {
    &_item {
      flex: 1 33%;
    }
  }
`;
export const flex4 = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  .flex {
    &_item {
      flex: 0 1 23%;
      border: 1px solid #fff;
      text-align: center;
    }
  }
`;

/** @jsxImportSource @emotion/react */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TalentQuestionaireInterface } from "../../../interface/TalentQuestionaireInterface";

type initialState = {
  getAllState: {
    talentQuestionaire: TalentQuestionaireInterface[];
    count: number;
  };
  getOneState: TalentQuestionaireInterface;
};

const initState: initialState = {
  getAllState: {
    talentQuestionaire: [],
    count: 0,
  },
  getOneState: {
    user: "",
    closestCityCenter: "",
    LinkedInLink: "",
    genderIndentity: "",
    expectedSalary: "",
    yearsOfExperience: "",
    desiredIndustrySegment: [],
    openToRoles: [],
    isRemoteJobImportant: "",
    mostImportantInNextJob: [],
    companyMostEnjoyed: "",
    jobSearchStatus: "",
    profilePaused: "",
    skillsYouPossess: [],
    favouriteCompany: "",
    uniqueTraits: "",
  },
};

const talentQuestionaire = createSlice({
  name: "talentQuestionaire",
  initialState: initState,
  reducers: {
    setAll: (
      state,
      action: PayloadAction<{ talentQuestionaire: TalentQuestionaireInterface[]; count: number }>
    ) => {
      state.getAllState = action.payload;
    },
    deleteOne: (state, action: PayloadAction<string>) => {
      state.getAllState.talentQuestionaire =
        state.getAllState.talentQuestionaire.filter(
          (item) => item._id !== action.payload
        );
      state.getAllState.count = state.getAllState.count - 1;
    },
    setOne: (state, action: PayloadAction<any>) => {
      state.getAllState.talentQuestionaire = [
        ...state.getAllState.talentQuestionaire,
        action.payload.data,
      ];
      state.getAllState.count = state.getAllState.count + 1;
    },
    getOne: (state, action: PayloadAction<any>) => {
      state.getOneState = action.payload;
    },
  },
});
export default talentQuestionaire.reducer;
export const { setAll, deleteOne, setOne, getOne } = talentQuestionaire.actions;

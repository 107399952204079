import { lazy } from "react";
import { RouteInterface } from "../interface/RouteInterface";
const Home = lazy(() => import("../pages/User/home/Home"));
const Login = lazy(() => import("../pages/auth/login/Login"));
const Register = lazy(() => import("../pages/auth/register/Register"));
const VerifiedUserLogin = lazy(() => import("../pages/auth/verifiedUserLogin/verifiedUserLogin"));
const ResetPassword = lazy(
  () => import("../pages/auth/resetPassword/ResetPassword")
);
const PageNotFound = lazy(() => import("../pages/User/404/PageNotFound"));

import UserRoutes from "./UserRoutes";
import FormRoutes from "./FormRoutes";
import AdminRoutes from "./AdminRoutes";

const normalChildRoutes = [
  {
    path: "/reset",
    component: ResetPassword,
    name: "ResetPassword",
  },
 ]

const AppRoutesData: RouteInterface[] = [
  {
    path: "/",
    component: Home,
    name: "Home",
    userChildRoutes: [...UserRoutes, ...FormRoutes],
    adminChildRoutes: AdminRoutes,
    normalChildRoutes: normalChildRoutes
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/register",
    component: Register,
    name: "Register",
  },
  {
    path: "/verified",
    component: VerifiedUserLogin,
    name: "VerifiedUserLogin",
  },
  {
    path: "*",
    component: PageNotFound,
    name: "PageNotFound",
  },
];

export default AppRoutesData;

import React, { createContext, useContext, useState, ReactNode } from "react";

interface MobileChatContextValue {
  isChatView: boolean;
  toggleChatView: () => void;
}

const MobileChatContext = createContext<MobileChatContextValue | undefined>(
  undefined
);

interface MobileChatProviderProps {
  children: ReactNode;
}

export const MobileChatProvider: React.FC<MobileChatProviderProps> = ({
  children,
}: MobileChatProviderProps) => {
  const [isChatView, setIsChatView] = useState(true);

  const toggleChatView = () => {
    setIsChatView(!isChatView);
  };

  const contextValue: MobileChatContextValue = {
    isChatView,
    toggleChatView,
  };

  return (
    <MobileChatContext.Provider value={contextValue}>
      {children}
    </MobileChatContext.Provider>
  );
};

export const useMobileChat = (): MobileChatContextValue => {
  const context = useContext(MobileChatContext);
  if (!context) {
    throw new Error("useMobileChat must be used within a MobileChatProvider");
  }
  return context;
};

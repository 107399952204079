import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../components/loader/Loader";
import { RouteInterface } from "../interface/RouteInterface";
import { AdminAuthGuard, AuthGuard } from "../helpers/Protected";

import AppRoutesData from "./AppRoutesData";
import { MobileChatProvider } from "../pages/User/chat/context/MobileChatContext";
import { FilterTagsProvider } from "../components/FilterDropdown/FilterContext";

const AppRoutes: React.FunctionComponent = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          {AppRoutesData.map((route: RouteInterface, index: number) => {
            return (
              <Route
                key={index}
                path={`${route.path}`}
                element={<route.component />}
              >
                {route.userChildRoutes
                  ? route.userChildRoutes.map(
                      (child: RouteInterface, index: number) => (
                        <Route
                          path={`${child.path}`}
                          key={index}
                          element={
                            <AuthGuard>
                              <FilterTagsProvider>
                                <MobileChatProvider>
                                  <child.component />
                                </MobileChatProvider>
                              </FilterTagsProvider>
                            </AuthGuard>
                          }
                        />
                      )
                    )
                  : null}

                {route.adminChildRoutes
                  ? route.adminChildRoutes.map(
                      (child: RouteInterface, index: number) => (
                        <Route
                          path={`${child.path}`}
                          key={index}
                          element={
                            <AdminAuthGuard>
                              <child.component />
                            </AdminAuthGuard>
                          }
                        />
                      )
                    )
                  : null}

                {route.normalChildRoutes
                  ? route.normalChildRoutes.map(
                      (child: RouteInterface, index: number) => (
                        <Route
                          key={index}
                          path={`${child.path}`}
                          element={<child.component />}
                        />
                      )
                    )
                  : null}
              </Route>
            );
          })}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;

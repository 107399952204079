/** @jsxImportSource @emotion/react */
window.global ||= window;
import { css } from "@emotion/react";
import { lazy, useEffect, useState } from "react";
import "./App.css";
import Loader from "./components/loader/Loader";
import { useAppSelector } from "./app/reduxHooks";
import AppRoutes from "./routes/AppRoutes";
import ReactGA from "react-ga";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./utils";
import { mainStyle } from "./styles/Layout";
import { Global } from "@emotion/react";
import { GlobalRule } from "./styles/Global";

const HotToast = lazy(() => import("./components/hotToast/HotToast"));
const TRACKING_ID: string = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || "Id";
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  const reduxState = useAppSelector((state) => state);
  const { loaderState } = reduxState;
  const { isLoading } = loaderState;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div css={mainStyle}>
      <Global styles={GlobalRule} />
      <ThemeProvider theme={lightTheme}>
        {isLoading ? <Loader /> : null}
        <AppRoutes />
        <HotToast />
      </ThemeProvider>
    </div>
  );
};

export default App;

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../pages/auth/authSlice";
import loaderSlice from "./loaderSlice";

import talentQuestionaireSlice from "../pages/User/talentQuestionaire/talentQuestionaireSlice";
import recruiterQuestionaireSlice from "../pages/User/recruiterQuestionaire/recruiterQuestionaireSlice";
import talentPoolSlice from "../pages/User/talentPool/talentPoolSlice";


const store = configureStore({
  reducer: {
    userState: authSlice,
    loaderState: loaderSlice,
    talentQuestionaireState: talentQuestionaireSlice,
    recruiterQuestionaireState: recruiterQuestionaireSlice,
    talentPoolState: talentPoolSlice,
  },
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { lazy } from 'react';
import { RouteInterface } from "../interface/RouteInterface";

const AdminUsers = lazy(() => import('../pages/Admin/adminUsers/AdminUsers'));
const AdminDashboard = lazy(() => import("../pages/Admin/adminDashboard/AdminDashboard"));
const UserDetails = lazy(() => import('../pages/Admin/userDetails/UserDetails'));
const Community = lazy(() => import('../pages/User/community/Feed'));


export const adminCollectionRoutes = [
  {
    path: "admin/users",
    component: AdminUsers,
    name: "AdminUsers",
  },
  {
    path: "admin/community",
    component: Community,
    name: "Community",
  }
]

const viewEditRoutes = [
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    name: "AdminDashboard",
  },
  {
    path: "/admin/user/:userId",
    component: UserDetails,
    name: "UserDetails",
  },
]



const AdminRoutes = [...adminCollectionRoutes, ...viewEditRoutes,]
export default AdminRoutes;


import { RootState } from "./../../app/store";
import { Axios } from "../../helpers/axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../app/store";
import {
  OAuthUserInterface,
  UserInterface,
  UserLoginInterface,
  UserSignUpInterface,
} from "../../interface/AuthInterface";
import { loader } from "../../app/loaderSlice";

type InitialState = {
  user: UserInterface | any;
  token: string | null;
  isLoggedIn: boolean;
  error: string | null;
  getStreamToken?: null | string;
};

const setUserLocalStorage = (user: UserInterface, token: string) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("jwt", JSON.stringify(token));
};

export const getItemFromLocalStorage = (name: string) => {
  const jsonData = localStorage.getItem(name);
  const data = jsonData ? JSON.parse(jsonData) : null;
  return data;
};

const jwtToken = getItemFromLocalStorage("jwt");
const user = getItemFromLocalStorage("user");
const getStreamToken = getItemFromLocalStorage("getStreamToken");

const initState: InitialState = {
  user: user,
  token: jwtToken,
  isLoggedIn: user && jwtToken ? true : false,
  error: "",
  getStreamToken:getStreamToken
};

const unsetUser = () => {
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("jwt");
  window.localStorage.removeItem("getStreamToken");
};

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ token: string; user: UserInterface; getStreamToken: string }>
    ) => {
      const { user, token, getStreamToken } = action.payload;
      state.user = user;
      state.token = token;
      state.getStreamToken = getStreamToken
      state.isLoggedIn = user && token ? true : false;
      state.error = "";
      if (user && token) {
        setUserLocalStorage(user, token);
      }
      if(getStreamToken){
        localStorage.setItem("getStreamToken", JSON.stringify(getStreamToken));
      }
    },
    setProfile: (state, action: PayloadAction<{ user: UserInterface }>) => {
      const user = action.payload;
      state.user = user;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    logOutUser: (state) => {
      unsetUser();
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
      state.error = "";
      state.getStreamToken = null;
    },
  },
});
export default authSlice.reducer;
export const { setUser, setProfile, setError, logOutUser } = authSlice.actions;

export const authThunk =
  (
    userData: {
      user: UserSignUpInterface | UserLoginInterface | OAuthUserInterface;
    },
    url: string
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch(loader(true));
      const { data } = await Axios.post(`/auth/${url}`, userData);
      dispatch(setUser(data));
      dispatch(loader(false));
    } catch (error: any) {
      dispatch(
        setError(
          error?.response?.data?.message || "Sorry, something went wrong."
        )
      );
      dispatch(loader(false));
    }
  };

export const loginAfterVerificationThunk =
  (email: string, url: string) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch(loader(true));
      const { data } = await Axios.get(`/auth/${url}/${email}`);
      dispatch(setUser(data));
      dispatch(loader(false));
    } catch (error: any) {
      dispatch(
        setError(
          error?.response?.data?.message || "Sorry, something went wrong."
        )
      );
      dispatch(loader(false));
    }
  };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
    import { RecruiterQuestionaireInterface } from "../../../interface/RecruiterQuestionaireInterface";

    type initialState = {
      getAllState: {
              recruiterQuestionaire: any[],
              count: number
      },
      getOneState: RecruiterQuestionaireInterface
}

const initState: initialState = {
  getAllState: {
    recruiterQuestionaire: [],
          count: 0
  },
  getOneState: {
          user:"",title:"",linkedInCompanyProfile:"",jobsPlacedEachYear:"",companyAddress:"",
  }

};
    
    const recruiterQuestionaire = createSlice({
      name: "recruiterQuestionaire",
      initialState: initState,
      reducers: {
              setAll: (state, action: PayloadAction<{ recruiterQuestionaire: any[], count: number }>) => {
                      state.getAllState = action.payload;
              },
              deleteOne: (state, action: PayloadAction<string>) => {
                      state.getAllState.recruiterQuestionaire = state.getAllState.recruiterQuestionaire.filter((item) => item._id !== action.payload)
                      state.getAllState.count = state.getAllState.count - 1
              },
              setOne: (state, action: PayloadAction<any>) => {
                      state.getAllState.recruiterQuestionaire = [...state.getAllState.recruiterQuestionaire, action.payload.data]
                      state.getAllState.count = state.getAllState.count + 1
              },
              getOne: (state, action: PayloadAction<any>) => {
                      state.getOneState = action.payload

              },
      },
});
export default recruiterQuestionaire.reducer;
export const { setAll, deleteOne, setOne, getOne } = recruiterQuestionaire.actions;

import { Navigate } from "react-router-dom";
import { useAppSelector } from "../app/reduxHooks";
import toast from "react-hot-toast";

const AuthGuard = ({
  children,
  redireactPath = "/login",
}: {
  children: React.ReactElement;
  redireactPath?: string;
}) => {
  const userState = useAppSelector((state) => state.userState);
  const { isLoggedIn } = userState;

  // if (!isLoggedIn) {
  //   toast.error("You should be logged in");
  //   return <Navigate to={redireactPath} replace />;
  // }
  return children;
};

const AdminAuthGuard = ({
  children,
  redireactPath = "/",
}: {
  children: React.ReactElement;
  redireactPath?: string;
}) => {
  const userState = useAppSelector((state) => state.userState);
  const { isLoggedIn, user } = userState;
  if (!user || !isLoggedIn) {
    return <Navigate to={redireactPath} replace />;
  } else {
    const { isSuperAdmin, isAdmin } = user;
    if (isSuperAdmin || isAdmin) {
      return children;
    } else {
      return <Navigate to={redireactPath} replace />;
    }
  }
};

export { AuthGuard, AdminAuthGuard };

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { primaryFont, lightTheme } from "../utils";

export const GlobalRule = css`
  body {
    font-family: ${primaryFont};
    font-size: 16px;
    font-weight: 300;
    color: ${lightTheme.palette.common.black};
    margin: 0;
  }

  img {
    max-width: 100%;
    display: block;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: block;
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
`;
